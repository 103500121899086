import "purecss";
import "@fortawesome/fontawesome-free/js/all.min.js"

//move to common.js
Date.prototype.toDateInputValue = (function() {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0,10);
});

import css from './style.css';
import indicators from './indicators.css';
import tooltip from './tooltip.css';
import calendarView from './calendarView.css';
